import { Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button } from "antd";
import Content from "../../components/content/Content";
import { SearchForm } from "../../components/search-form/SearchForm";
import { getBunJudgements, BunJudgements, deleteBunJudgement } from "../../services/api";

const { Title } = Typography;

export const BunJudgementsPage: React.FC = (props) => {
    const [bunJudgements, setBunJudgements] = useState<BunJudgements | []>([]);
    const [target, setTarget] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();
    const tableHeaders = [
        {
            title: 'Analyzer',
            dataIndex: 'analyzer',
            key: 'analyzer',
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Ban expired',
            dataIndex: 'exp',
            key: 'exp',
            render: (value: number) => {
                const expiredDate = new Date(+value * 1000);
                return expiredDate.toLocaleString();
            },
        },
        {
            key: 'amnesty',
            width: 100,
            render: (record: { key: React.Key }) => <Button onClick={() => handleDeleteBunJudgement(record.key)} type="primary" danger>Amnesty</Button>
        },
    ];
    const tableRows = bunJudgements.map((bunJudgement, index) => ({ key: index, ...bunJudgement }));

    async function fetchBunJudgement(searchString: string) {
        setBunJudgements([]);
        setError(null);

        if (searchString.length) {
            history.push(`/bunjudgements?searchString=${searchString}`);
            try {
                const bunJudgementsResponse = await getBunJudgements(searchString);
                setBunJudgements(bunJudgementsResponse.ban_judgements);
                setTarget(searchString);
            } catch (e) {
                setBunJudgements([]);
                // @ts-ignore
                setError(e.message);
                console.log(error);
            }
        } else {
            history.push('/bunjudgements');
        }
    }

    async function handleDeleteBunJudgement(recordKey: React.Key) {
        const analyzer = tableRows.find((item) => item.key === recordKey)?.analyzer || "";
        try {
            await deleteBunJudgement(target, analyzer);
            const bunJudgementsResponse = await getBunJudgements(target);
            setBunJudgements(bunJudgementsResponse.ban_judgements);
            setTarget(target);
        } catch (e) {
            setBunJudgements([]);
            // @ts-ignore
            setError(e.message);
            console.log(error);
        }
    };

    return (
        <Content>
            <Title level={5}>Bun Judgements</Title>
            <SearchForm onFinish={fetchBunJudgement} formPlaceholder="Search bun judgements" />
            <br />
            <br />
            <Table dataSource={tableRows} columns={tableHeaders} bordered />
            <br />
            <br />
        </Content>
    );
};

export default BunJudgementsPage;
