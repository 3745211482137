import { Auth } from "aws-amplify";
import { configInst } from "../utils/config";

const backendFetch = async (path: string, init?: RequestInit) => {
    const { backendUrl } = await configInst();
    const backendBaseUrl = `${backendUrl}/api/v1`;
    const session = await Auth.currentSession();

    return fetch(`${backendBaseUrl}${path}`, {
        headers: {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        },
        ...init,
    });
};

export interface Admin {
    username: string;
}

export const getSystemAdmin = async (): Promise<Admin> => {
    return await (await backendFetch("/auth")).json();
};

export type BunJudgement = {
    analyzer: string,
    reason: string,
    exp: number,
}

export type BunJudgements = Array<BunJudgement>;
export type BunJudgementsResponse = {
    target: string,
    ban_judgements: BunJudgements
};

export interface SuppressedUser {
    email: string,
    reason: string,
    lastUpdate: number,
}

export const getBunJudgements = async (target: string): Promise<BunJudgementsResponse> => {
    const response = await (
        await backendFetch(`/ban-records/${target}`)
    ).json();
    if (response.message) throw new Error(response.message);
    return { ...response, target };
}

export const deleteBunJudgement = async (target: string, analyzer: string) => {
    return await (
        await backendFetch(`/ban-records/${target}?analyzer=${analyzer}`, {
            method: "DELETE"
        })
    ).json();
}

export const getSuppressedUser = async (email: string): Promise<SuppressedUser> => {
    const response = await (
        await backendFetch(`/suppression-list/${email}`)
    ).json();
    if (response.message) throw new Error(response.message);
    return { email, reason: response?.reason, lastUpdate: response?.last_update };
}

export const deleteSuppressedUser = async (email: string) => {
    return await (
        await backendFetch(`/suppression-list/${email}`, {
            method: "DELETE"
        })
    ).json();
}

