import React, {useState} from "react";
import Search from "antd/lib/input/Search";

interface ISearchForm {
    onFinish: (result: any) => Promise<any>;
    formPlaceholder: string;
}

export const SearchForm: React.FC<ISearchForm> = ({onFinish, formPlaceholder}) => {
    const [loading, setLoading] = useState<boolean>(false);

    async function onSearch(searchQueryString: string) {
        setLoading(true);
        await onFinish(searchQueryString);
        setLoading(false);
    }

    return <>
        <Search
            allowClear
            size="large"
            placeholder={formPlaceholder}
            enterButton="Search"
            loading={loading}
            onSearch={onSearch}
        />
    </>
}

export default SearchForm;
