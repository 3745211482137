import React from "react";
import { Button, Card, Col, Row } from "antd";
import { SuppressedUser } from "../../services/api";

export type UserCardProps = {
    user: SuppressedUser,
    handleDeleteUser: any
}

export const UserCard: React.FC<UserCardProps> = ({ user, handleDeleteUser }) => {
    function timeFormat(timestamp: number) {
        const date = new Date(+timestamp * 1000);
        return date.toLocaleString();
    }

    return (
        <Card title={<span>User: <b>{user.email}</b></span>} extra={<Button type="primary" danger onClick={handleDeleteUser}>Remove</Button>}>
            <Row gutter={16}>
                <Col span={2}>Reason</Col>
                <Col span={14}>{user.reason}</Col>
            </Row>
            <br />
            <Row gutter={16}>
                <Col span={2}>Last update</Col>
                <Col span={14}>{timeFormat(user.lastUpdate)}</Col>
            </Row>
        </Card>
    )
}

export default UserCard;