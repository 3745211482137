import { Row, Typography } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Content from "../../components/content/Content";
import { SearchForm } from "../../components/search-form/SearchForm";
import UserCard from "../../components/user-card/UserCard";
import { SuppressedUser, getSuppressedUser, deleteSuppressedUser } from "../../services/api";

const { Title } = Typography;

export const SuppressionListPage: React.FC = (props) => {
    const [email, setEmail] = useState<string>("");
    const [suppressionUser, setSuppressionUser] = useState<SuppressedUser | null>(null);
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();

    async function fetchSuppressedUser(searchString: string) {
        setSuppressionUser(null)
        setError(null);

        if (searchString.length) {
            history.push(`/supression-list?searchString=${searchString}`);
            try {
                const suppressedUserResponse = await getSuppressedUser(searchString);
                setSuppressionUser(suppressedUserResponse);
                setEmail(searchString);
            } catch (e) {
                setSuppressionUser(null);
                // @ts-ignore
                setError(e.message);
            }
        } else {
            history.push('/supression-list');
        }
    }

    async function deleteUser() {
        console.log(email)
        try {
            await deleteSuppressedUser(email);
            const suppressedUserResponse = await getSuppressedUser(email);
            setSuppressionUser(suppressedUserResponse);
            setEmail(email);
        } catch (e) {
            setSuppressionUser(null);
            // @ts-ignore
            setError(e.message);
        }
    }

    return (
        <Content>
            <Title level={5}>Supression List</Title>
            <SearchForm onFinish={fetchSuppressedUser} formPlaceholder="Search user by email" />
            <br />
            <br />
            {suppressionUser
                ? <UserCard user={suppressionUser} handleDeleteUser={deleteUser} />
                : <Row justify="center"><Title level={4} disabled>{error}</Title></Row>}
        </Content>
    );
};

export default SuppressionListPage;
