import React from "react";
import { Menu } from "antd";
import {
  SecurityScanOutlined,
  RadarChartOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Logo } from "../logo/Logo";

export interface NavbarProps {
  onLogout?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLogout }) => {
  return (
    <Menu mode="horizontal" >
      <Menu.Item key="1" disabled>
        <Logo />
      </Menu.Item>
      <Menu.Item key="2" icon={<SecurityScanOutlined />}>
        <Link to="/bunjudgements">Bun Judgements</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<RadarChartOutlined />}>
        <Link to="/supression-list">Supression List</Link>
      </Menu.Item>
      <Menu.Item
        style={{ marginLeft: "auto" }}
        key="4"
        icon={<LogoutOutlined />}
        onClick={onLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
